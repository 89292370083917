import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, Collapse, Divider, IconButton, styled, Tab, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Download, ExpandMoreOutlined, RemoveCircleOutline, Visibility } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { useFormError } from '../../../components/Datatable/FormErrorProvider';
import { useNotify } from '../../../components/Datatable/NotifyProvider';
import api, { create, get_one, update, delete_one } from '../../../services/api';
import FormDialog from '../../../components/Datatable/FormDialog';
import Datatable from '../../../components/Datatable';
import CustomTextField from '../../../components/CustomTextField';
import { useAuth } from '../../../components/AuthProvider';
import moment from 'moment';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { red } from '@mui/material/colors';
import fileDownload from 'js-file-download';

export default function Budget() {
    const notify = useNotify()
    const auth = useAuth()
    const [formState, setFormState] = useState({
        open: false,
        id: null,
    })
    const [refresh, setRefresh] = useState(false)
    React.useEffect(() => {
        document.title = "IPV7 Enterprise - Budget"
    }, [])
    return (
        <>
            <FormItem
                open={formState.open}
                id={formState.id}
                handlerClose={() => setFormState({ open: false, id: null })}
                handlerSubmit={() => {
                    setFormState({ open: false, id: null })
                    setRefresh(true)
                }}
                btnSubmit={formState.id ? 'Atualizar' : 'Salvar'}
                btnClose={'Fechar'}
            ></FormItem>
            <Datatable
                refresh={refresh}
                handlerRefresh={() => setRefresh(!refresh)}
                title='Orçamentos'
                endpoint='/budget/'
                options={{
                    customToolbar: () =>
                        <>
                            <Tooltip title="Remover todos os filtros">
                                <IconButton onClick={() => { localStorage.removeItem("datatable./budget/"); location.reload() }} >
                                    <RemoveCircleOutline />
                                </IconButton>
                            </Tooltip>

                        </>
                    ,
                    customToolbarSelect: (selectedRows, displayData, setSelectedRows) => ""
                }
                }
                columns={[
                    {
                        name: 'id',
                        label: 'ID',
                        options: {
                            display: true
                        }
                    },
                    {
                        name: 'lead_name',
                        label: 'Lead',
                    },
                    {
                        name: 'description',
                        label: 'Descrição',
                    },
                    {
                        name: 'activation',
                        label: 'Ativação',
                        options: {
                            customBodyRender: value => <span>{value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>,   
                        }
                    },
                    {
                        name: 'mensalidade',
                        label: 'Mensalidade',
                        options: {
                            customBodyRender: value => <span>{value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>,
                        }
                    },
                    {
                        name: 'total',
                        label: 'Total',
                        options: {
                            customBodyRender: value => <span>{value?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</span>,
                        }
                    },
                    {
                        name: 'created_at',
                        label: 'Criado em',
                        options: {
                            customBodyRender: value => <span>{moment(value).locale('pt-br').format('DD-MM-YYYY HH:mm:ss')}</span>,
                        }
                    },
                    {
                        name: 'actions',
                        label: 'Ações',
                        options: {
                            filter: false,
                            sort: false,
                            customBodyRender: (value, tableMeta, updateValue) => {
                                return (
                                    <>
                                        <Tooltip title="Visualizar">
                                            <IconButton disabled={!auth.hasPerm('budget.get_all')}
                                                onClick={() => setFormState({ open: true, id: tableMeta.rowData[0] })}
                                            >
                                                <Visibility />

                                            </IconButton>
                                        </Tooltip>

                                    </>
                                )
                            }
                        }
                    }
                ]}

            />
        </>
    )
}




function FormItem({ id, open, handlerClose, handlerSubmit, btnClose = 'Fechar', btnSubmit = 'Salvar' }) {
    const notify = useNotify()
    const error = useFormError()
    const [isLoaded, setIsLoaded] = React.useState(true)
    const [expanded, setExpanded] = React.useState([])

    const [state, setState] = useState([])

    useEffect(() => {

        if (!open) return
        error.setErrors([])
        if (id) {
            setExpanded([])
            setIsLoaded(false)
            get_one('/budget', id).then(res => {
                setState(res.data)

                setIsLoaded(true)

            }).catch(e => {
                console.log(e)
                setIsLoaded(true)
            })
        }
    }, [id, open])

    const handleChange = (event) => {
        setState({ ...state, [event.target.name]: event.target.value });
    }

    const wrapperSubmit = () => {

        if (id) {
            update('/budget/', id, state).catch(e => notify
                .setNotify({ open: true, message: e.message, severity: 'error' }))
                .then(res => {
                    if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerClose()
                    notify.setNotify({ open: true, message: 'Budget atualizado com sucesso', severity: 'success' })
                    error.setErrors([])
                }).catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }
        else {
            create('/budget/lead/create', state)
                .then(res => {
                    if (res.data.error) {
                        notify.setNotify({ open: true, message: res.data.error, severity: 'error' })
                        return
                    }
                    if (res.data.errors) {
                        error.setErrors(res.data.errors)
                        return
                    }
                    handlerClose()
                    notify.setNotify({ open: true, message: 'Budget criado com sucesso.', severity: 'success' })
                    error.setErrors([])
                })
                .catch(e =>
                    notify.setNotify({ open: true, message: e.message, severity: 'error' }))
        }

    }
    const auth = useAuth()

    return (
        <Box component="form" onSubmit={wrapperSubmit} sx={{ m: 2 }} display="flex" flexWrap="wrap">
            <FormDialog
                maxWidth='lg'
                isLoaded={isLoaded}
                title={
                    <>
                        <Box m={2} display="flex" justifyContent="space-between">
                            <Typography variant="h5">{state.cnpj} - {state.company_name}</Typography>
                            <Box>

                                <Tooltip title="Baixar PDF">
                                    <IconButton
                                        onClick={() => {
                                          api.get(`/budget/download/${id}`, { responseType: 'blob' }).then(res => {
                                            fileDownload(res.data, `Budget #${id}-${state?.description}-${state.company_name}.pdf`)
                                        }).catch(e =>console.log(e))
                                        }}

                                    ><Download /></IconButton>
                                </Tooltip>
                                <Tooltip title="Deletar">
                                    <IconButton disabled={!auth.hasPerm('budget.delete')}
                                        onClick={() => {
                                            const answer = confirm('Deseja realmente deletar este budget?')
                                            if (!answer) return
                                            delete_one('/budget', id)
                                                .then(res => {
                                                    notify.setNotify({ open: true, message: 'Budget deletado com sucesso.', severity: 'success' })
                                                    handlerSubmit()
                                                })
                                                .catch(e => notify.setNotify({ open: true, message: e.message, severity: 'error' }))
                                        }}
                                    >
                                        <DeleteIcon />
                                    </IconButton>
                                </Tooltip>
                            </Box>
                        </Box>
                        <Divider />
                        <Box m={2}>

                            {state.obs && state.obs.split('\n').map((item, key) => {
                                return <Typography key={key} variant='body2' >{item}</Typography>
                            }
                            )}
                        </Box>
                    </>
                }
                open={open}
                handlerSubmit={wrapperSubmit}
                handlerClose={handlerClose}
                btnSubmit={false}
                btnClose={btnClose}
            >
                <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Produto</TableCell>
                                <TableCell>Localização</TableCell>
                                {/* <TableCell>Quantidade</TableCell> */}
                                
                                <TableCell>Ativação</TableCell>
                                <TableCell>Recursos</TableCell>
                                <TableCell>Subtotal</TableCell>
                                <TableCell>Subtotal Ativação</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {state?.products?.map((product, index) => (
                                <>
                                    <TableRow key={index}>
                                        <TableCell>
                                            <IconButton
                                                aria-label="expand row"
                                                size="small"
                                                onClick={() => {
                                                    const newExpanded = [...expanded];
                                                    newExpanded[index] = !expanded[index];
                                                    setExpanded(newExpanded);
                                                }}
                                            >
                                                <ExpandMore
                                                    expand={expanded[index]}
                                                    aria-expanded={expanded[index]}
                                                    aria-label="show more"
                                                >
                                                    <ExpandMoreOutlined />
                                                </ExpandMore>
                                            </IconButton>
                                        {product.description}</TableCell>
                                        <TableCell>{product.location}</TableCell>
                                        {/* <TableCell>{product.quantity}</TableCell> */}
                                        <TableCell>{product.subtotal?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                        <TableCell>{product.activation_price?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                        
                                        <TableCell>{product.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                        <TableCell>{product.activation?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</TableCell>
                                    </TableRow>
                                    <Collapse in={expanded[index]} timeout="auto" unmountOnExit>
                                        <TableContainer>
                                            <Table>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Recursos</TableCell>
                                                        <TableCell>Quantidade</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {product.services.map((service, index) => (
                                                        <TableRow key={index}>
                                                            {!service.multiple && service.min_quantity == 0 ?
                                                                <>
                                                                    <TableCell>{service.type_service}</TableCell>
                                                                    <TableCell>{service.quantity > 1 ? service.quantity + " " + service.unit : service.description}</TableCell>
                                                                </>
                                                                :
                                                                <>
                                                                    <TableCell>{service.description}</TableCell>
                                                                    <TableCell>{service.quantity} {service.unit}</TableCell>
                                                                </>
                                                            }
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Collapse>
                                </>
                            ))}
                            <TableRow>
                                <TableCell colSpan={3}></TableCell>
                                <TableCell
                                    sx={{ fontSize: '1.2em' }}
                                >{state.discount ? "Subtotal" : "Total"}</TableCell>
                                <TableCell sx={{ fontSize: '1.2em' }}>
                                    {state?.total?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </TableCell>
                                <TableCell sx={{ fontSize: '1.2em' }}>
                                    {state?.activation?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                </TableCell>
                            </TableRow>
                            {state.discount > 0 &&
                                <>
                                    <TableRow>
                                        <TableCell colSpan={3}></TableCell>
                                        <TableCell
                                            sx={{ fontSize: '1.2em' }}
                                        >Desconto</TableCell>
                                        <TableCell sx={{ fontSize: '1.0em', color: red[500] }}>
                                            {(state?.discount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} ({String(Math.round(state.discount/(state.total+state.discount)*1000)/10).replace(".",",")}%)
                                        </TableCell>
                                        <TableCell sx={{ fontSize: '1.0em', color: red[500] }}>
                                            {(0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })} (0%)
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell colSpan={3}></TableCell>
                                        <TableCell
                                            sx={{ fontSize: '1.2em' }}
                                        >Total</TableCell>
                                        <TableCell colSpan={2} sx={{ fontSize: '1.2em' }}>
                                            {(state.total + state.activation).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </TableCell>
                                        
                                    </TableRow>
                                </>
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </FormDialog>
        </Box>
    );
}

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));
